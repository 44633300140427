<template>
  <en-table-layout
    :tableData="tableData.data"
    :loading="loading"
    @selection-change="handleSelectionChange"
  >
    <template slot="toolbar">
<!--      <div class="toolbar-title">登录日志</div>-->
    </template>

    <template slot="table-columns">
      <el-table-column
        prop="create_date"
        label="时间"
      >
        <template slot-scope="scope">
          {{scope.row.create_date | unixToDate("yyyy-MM-dd hh:mm:ss")}}
        </template>
      </el-table-column>
      <el-table-column
        prop="ip"
        label="ip"
      />
      <el-table-column
        prop="account_name"
        label="登录人员"
      />
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="tableData.page_no"
      :page-size="tableData.page_size"
      :total="tableData.data_total"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
    >
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_Log from "@/api/log";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
export default {
  name: "loginLog",
  components: { EnTableLayout },
  data() {
    return {
      value: "",
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        type: 1, //登录日志
        account_type: 4, //账户类型 4大平台
      },
      // 商品数据
      tableData: {},
    };
  },
  mounted() {
    this.GET_AccountLogList();
  },
  activated() {
    this.GET_AccountLogList();
  },
  watch: {},
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountLogList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountLogList();
    },

    /** 获取日志 */
    GET_AccountLogList() {
      this.loading = true;
      API_Log.getAccountLog(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.goods_id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
